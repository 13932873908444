body {
  margin: 0px auto;
}

.app-container {
  padding-top: 3rem;
  padding-bottom: 3rem;
  max-width: 600px;
  margin: 0px auto;
}

.navbar {
  margin: 0px auto;
}

.navbar-toggler {
  border: none;
}

.navColor {
  padding-left: 20px;
  padding-right: 20px;
}

.logo {
  max-width: 150px;
}

.invite-logo {
  border: 1px solid #ccd;
  padding: 4px;
  width: 340px;
  height: 200px;
  object-fit: cover;
  object-position: top;
}

.navbar-brand {
  margin: 0px;
}

.nav-logo {
  margin-right: 25%;
}

.mealButton {
  color: rgb(33, 37, 41);
}

.addMealButton {
  padding: 0.375rem 0.75rem;
  margin: 0;
  color: #aaa;
  font-size: 1.2em;
  text-align: center;
}

.meal-recipe-input {
  font-size: 14px;
}

.meal-grocery-list-input-button {
  border-color: #ccc;
}

.meal-recipe-input-button {
  border-color: #ccc;
  padding-top: 3.5px;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 5.5px;
}

.button-spinner {
  height: 1.2em;
  width: 1.2em;
  border-width: 0.15em;
}

#ai-url {
  font-size: 14px;
}

.ant-table-thead > tr > th {
  padding: 8px !important;
}

.ant-table-tbody > tr > td {
  padding: 8px !important;
  cursor: pointer;
}

.store-picker {
  cursor: pointer;
  margin-left: -7px;
}

.ig-stores > .input-group-text {
  width: 80%;
  font-size: 0.9em;
}

.ig-stores > input {
  font-size: 0.9em;
}

.about-text {
  font-size: 0.8em;
}

.site-nav {
  background-color: #18a167;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

#offcanvas {
  max-width: 40%;
  margin: 0px auto;
}

.offcanvas-main {
  max-width: 65%;
  margin: 0px auto;
}

.about-div {
  position: fixed;
  bottom: 0;
}

div.offcanvas-main a {
  text-decoration: none;
  font-size: 1.1em;
  color: #333;
}

.menu-today {
  font-size: 0.9em;
  background-color: yellow;
}

.menu-text {
  font-size: 0.7em;
  padding-bottom: 2px;
}

.menu-slot {
  min-height: 4.3em;
  cursor: pointer;
  border: 1px solid #ddd;
}

.addMenu-button {
  font-size: 0.7em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

.addMenu-button b {
  font-size: 1.1em;
}

.menu-active {
  border-bottom: 1px solid #fff;
}

.modal-buttons {
  border-top: 1px solid #eee;
  padding-top: 1em;
}

.modal-title .small {
  font-size: 0.6em;
  font-weight: 400;
}

#add-meal-header .btn-close {
  margin-bottom: auto;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-grow-top {
  flex-grow: 1;
  z-index: 100001;
}

.flex-grow-bottom {
  flex-grow: 1;
  z-index: 100000;
}

.fixed-top,
.fixed-bottom {
  z-index: 100002;
}

div.fade.modal.show {
  z-index: 100003;
}

.offcanvas-main {
  z-index: 100004;
}

.offcanvas-option {
  margin-bottom: 0.5em;
  font-size: 1.2em;
}

body.modal-open .offcanvas-backdrop,
body.modal-open .modal-backdrop {
  z-index: 100003;
}

.site-menu .offcanvas {
  max-width: 60%;
  background-color: #333;
}

.item-meal-list {
  font-size: 0.9em;
}

.store-aisle > input {
  font-size: 1em;
}

.store-aisle .aisle-input {
  max-width: 3.5em;
  text-align: center;
}

.dot {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.navColor {
  color: #4d4343;
}

.ant-tabs-dropdown {
  z-index: 100002;
}

#shop-tabs .ant-tabs-nav {
  margin-bottom: 0px;
}

#navbar-shop {
  display: none;
  --bs-navbar-padding-y: 0rem;
}

.shopping-mode-text {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font-size: 0.8em;
  margin-left: auto;
  margin-right: auto;
}

.groceryPicker {
  font-size: 1em;
}

.selected-theme-color {
  box-shadow: 0px 0px 0px 4px rgb(0 0 0 / 15%);
}

.profile-pic {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.settings-row {
  padding-right: 0 !important;
}

.settings-row > div {
  min-height: 3em;
  border-top: #ccc 1px solid;
  padding-top: 0.75em;
  font-size: 0.9em;
  padding-right: 0 !important;
}

.cat {
  border-radius: 5%;
  border: 2px solid rgb(167, 167, 167);
}

.reorder-group {
  width: 100vw;
  height: 100vh;
  /* background: #c9c9c9; */
  overflow: hidden;
  /* padding: 0; */
  /* margin: 0; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
}

.reorder-group > ul {
  position: relative;
  width: 300px;
}

.reorder-group > li {
  border-radius: 5px;
  margin-bottom: 5px;
  width: 50%;
  padding: 5px 10px;
  /* position: relative; */
  background: #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  cursor: grab;
}

.reorder-group > ul > li {
  list-style: none;
  padding: 0;
  width: 100%;
  /* margin: 0; */
  /* font-family: "Poppins", sans-serif; */
  font-weight: 500;
  font-size: 14px;
}

.text-justify {
  text-align: justify;
}

.new-menu-text {
  font-size: 0.9em;
}

.day_picker {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin-bottom: 0px;
  justify-content: space-between;
}

.day_picker li {
  float: left;
  width: 60px;
  height: 30px;
  position: relative;
  margin-left: 2px;
  margin-right: 2px;
}

.day_picker label,
.day_picker input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.day_picker label {
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.day_picker input {
  opacity: 0;
}

.day_picker input:checked + label {
  background: #f2c955;
}

.smaller {
  font-size: 0.78em;
}

.google-visualization-table-div-page [role='button'] {
  font-size: 26px !important;
  line-height: 26px !important;
}
